/* global app*/

$(document).ready(function() {
  window.app = window.app || {};

  app.instantiate = function(elem) {
    var $this = $(elem);
    var module = $this.attr('data-module');
    
    if (module === undefined) {
      throw new Error('Module not defined (use data-module="")');
    } else if (module in app) {
      if ($this.attr('data-initialized') === 'true') return;
      new app[module](elem);
      $this.attr('data-initialized', true);
    } else {
      throw new Error('Module "' + module + '" not found');
    }
  };

  $('[data-module]').each(function() {
    app.instantiate(this);
  });

});
