/*global window,jQuery*/

(function($, app) {
  'use strict';

  app.helper = function (element) {

    // Only 1 configuration >> Singleton
    if (app.helper.prototype._instance) {
      return app.helper.prototype._instance;
    }

    app.helper.prototype._instance = this;

    this.toAnchor = function(anchor) {
      var $target = $('#' + anchor);

      var $mainNavigation = $('[data-module="mainNavigation"]');
      var targetOffsetTop = $target.length ? $target.offset().top : 0;

      $('html, body').animate({ scrollTop: targetOffsetTop - $mainNavigation.outerHeight() }, 500);
    };

    this.setCookie = function(name, value, expires, path, domain, secure) {
      document.cookie = name + "=" + escape(value) +
        ((expires) ? "; expires=" + expires : "") +
        ((path) ? "; path=" + path : "") +
        ((domain) ? "; domain=" + domain : "") +
        ((secure) ? "; secure" : "");
    }

    this.getCookie = function(name) {
      var cookie = " " + document.cookie;
      var search = " " + name + "=";
      var setStr = null;
      var offset = 0;
      var end = 0;
      if (cookie.length > 0) {
        offset = cookie.indexOf(search);
        if (offset != -1) {
          offset += search.length;
          end = cookie.indexOf(";", offset)
          if (end == -1) {
            end = cookie.length;
          }
          setStr = unescape(cookie.substring(offset, end));
        }
      }
      return(setStr);
    }

  };
})(jQuery, window.app = window.app || {});
