/* global window,jQuery */

(function ($, app) {

  app.haversine = function(start, end, options) {

    var startLatitude, endLatitude, longitudeDelta, c;

    var earth = {
      MILE: 3959,
      KM: 6371,
      M: 6371000,
      NMI: 3440
    };

    function coordinateDifference(a, b, isRadians) {
      var delta = b - a;
      return getRadians(delta, isRadians);
    }

    function getRadians(input, isRadians) {
      return isRadians ? input : (input * (Math.PI / 180));
    }

    function defaults(options) {
      options = options || {};
      options.radius = options.radius || earth.MILE;
      options.isRadians = (typeof options.isRadians === 'boolean') ? options.isRadians : false;

      return options;
    }

    options = defaults(options);

    startLatitude = getRadians(start.latitude, options.isRadians);
    endLatitude = getRadians(end.latitude, options.isRadians);
    longitudeDelta = coordinateDifference(start.longitude, end.longitude, options.isRadians);

    c = Math.acos(Math.sin(startLatitude) * Math.sin(endLatitude) + Math.cos(startLatitude) * Math.cos(endLatitude) * Math.cos(longitudeDelta));

    return options.radius * c;

  };

}(jQuery, window.app = window.app || {}));