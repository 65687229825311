/* global window,jQuery */

(function ($, app) {

  function debounce(func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  app.mainNavigation = function(element) {
    // Only 1 configuration >> Singleton
    if (app.mainNavigation.prototype.instance) {
      return app.mainNavigation.prototype.instance;
    }
    app.mainNavigation.prototype.instance = this;

    var $element        = $(element);
    var $toggle         = $(element).find();
    var $link           = $element.find('[data-main-nav-link]');
    var $scrollTargets  = $('[data-scroll-target]');
    var $window         = $(window);
    var DEBOUNCE_DELAY  = 100;
    var helper          = new app.helper();

    function initialize() {
      addEventListeners();
      update();
    }

    function addEventListeners() {
      $element.on('click', '[data-main-nav-toggle]', handleClickToggle);
      $element.on('click', '[data-main-nav-link]', handleClickLink);
      $window
        .on('scroll', debounce(update, DEBOUNCE_DELAY))
        .on('hashchange', update);
    }

    function handleClickToggle(event) {
      event.preventDefault();
      $element.toggleClass('mainheader--collapsed');
    }

    function handleClickLink(event) {
      event.preventDefault();

      var $this = $(event.currentTarget);
      var anchor = $this.attr('href').split('#')[1];
      var $target = $('#' + anchor);

      if (!$target.length) {
        document.location.href = "/#" + anchor;
        return;
      }

      $link.removeClass('is-active');
      $this.addClass('is-active');
      helper.toAnchor(anchor);
    }

    function setActive($elem) {
      $('#link-' + $elem.attr('id')).addClass('is-active');
    }

    function update() {
      var windowScrollTop = $(window).scrollTop();
      var hasActive = false;

      if (!$scrollTargets.length) {
        return;
      }
      
      // first reset all
      $link.removeClass('is-active');

      $scrollTargets.each(function(i) {
        var $target = $(this);
        var offsetTop = $target.offset().top + $element.outerHeight();

        if (windowScrollTop < offsetTop) {
          setActive($target);
          hasActive = true;
          return false;
        }
      });

      if (!hasActive) {
        setActive($scrollTargets.last());
      }
    };

    initialize();
  };

}(jQuery, window.app = window.app || {}));
