/*global window,jQuery*/

(function($, app) {
  'use strict';

  app.cookiebar = function (element) {
    
    // Only 1 configuration >> Singleton
    if (app.cookiebar.prototype._instance) {
      return app.cookiebar.prototype._instance;
    }

    app.cookiebar.prototype._instance = this;

    var $element            = $(element);
    var $btn                = $element.find('button');
    var helper              = new app.helper();

    function initialize() {
      var cookieConsent       = !!helper.getCookie('cookie-consent');;
     
      if (cookieConsent) {
        return;
      }

      show();
      addEventListeners();      
    }

    function show() {
      $element.show();
    }

    function addEventListeners() {
      $btn.on('click', function () {
        var date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        helper.setCookie('cookie-consent', true, date.toUTCString());
        $element.hide();
      });
    }

    initialize();

  };
})(jQuery, window.app = window.app || {});
