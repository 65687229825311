/* global window,jQuery */

(function ($, app) {

  app.locationRedirect = function(element) {
    // Only 1 configuration >> Singleton
    if (app.locationRedirect.prototype.instance) {
      return app.locationRedirect.prototype.instance;
    }
    app.locationRedirect.prototype.instance = this;

    var defaults    = {
      loadingText: 'Bezig met laden...',
      demo: false
    };
    var $element    = $(element);
    var elemText    = $element.text();
    var config      = $element.data('config');

    var myLatLng, nearest, API_ENDPOINT;

    // Merge data-config with default settings
    if (typeof config === 'string' && config !== '') {
      config = JSON.parse(decodeURIComponent(config));
    }
    config = $.extend({}, defaults, config);
    API_ENDPOINT = config.demo ? '/api/locations.js' : '/locatie';

    function initialize() {
      addEventListeners();

    }

    function addEventListeners() {
      $element.on('click', handleClick);
    }

    function handleClick() {
      if (!navigator.geolocation) {
        return useDefault();
      }

      $element
        .text(config.loadingText)
        .attr('disabled', true)
        .addClass('button--loading');
      navigator.geolocation.getCurrentPosition(handleGetPosition, useDefault, { enableHighAccuracy: false });
    }

    function getLocations(callback) {
      $.getJSON({ url: API_ENDPOINT }).done(callback);
    }

    function handleGetPosition(position) {
      myLatLng = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };

      getLocations(function(json) {
        nearest = {
          distance: 999999,
          url: json.default.url
        };
        findNearest(json);
      });
    }

    function findNearest(json) {
      for (var i = 0; i < json.locations.length; i++) {
        var location = json.locations[i];
        var locationLatLng = {
          latitude: location.lat,
          longitude: location.lng
        };
        location.distance = app.haversine(myLatLng, locationLatLng) - location.radius;
        nearest = nearest.distance > location.distance ? location : nearest;
      }

      document.location.href = nearest.url;
    }

    function useDefault() {
      getLocations(function(json) {
        document.location.href = json.default.url;
      });
    }

    initialize();
  };

}(jQuery, window.app = window.app || {}));
