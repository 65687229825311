/*global window,jQuery*/

(function($, app) {
  'use strict';

  app.skipTo = function (element) {

    var $element = $(element);
    var helper = new app.helper();

    function initialize() {
      addEventListeners();
    }

    function addEventListeners() {
      $element.on('click', handleClick);
    }

    function handleClick(event) {
      var href = $element.attr('href');
      var isAnchorLink = href.indexOf('#') > -1
      
      if (!isAnchorLink) {
        return;
      }

      event.preventDefault();
      helper.toAnchor(href.replace('#', ''));
    }

    initialize();

  };
})(jQuery, window.app = window.app || {});
