/*global window,jQuery,google*/

(function($, app) {
	'use strict';

	app.googleMap = function (element) {

		// Only 1 configuration >> Singleton
		if (app.googleMap.prototype._instance) {
			return app.googleMap.prototype._instance;
		}

		app.googleMap.prototype._instance = this;

		var $element            = $(element);
		var config              = $element.data('config');
		var defaults            = {
			lat: 52.35402,
			lng: 4.92862,
			info: false,
			name: '',
			info: ''
		};
		var map, latLng, infowindow;

		// Merge data-config with default settings
		if (typeof config === 'string' && config !== '') {
			config = JSON.parse(decodeURIComponent(config));
		}
		config = $.extend({}, defaults, config);

		// Initialize
		function initialize() {
			setupMap();
			addInfoWindow();
			addMarker();
		}

		function getMapOptions() {
			return {
				mapTypeControl: false,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				zoomControlOptions: {
					style: google.maps.ZoomControlStyle.SMALL
				},
				zoom: 16,
				minZoom: 7,
				scrollwheel: false,
				streetViewControl: false,
				styles: [
					{
						featureType: "poi",
						stylers: [{
							visibility: "off"
						}]
					},
					{
						featureType: "road",
						stylers: [{
							saturation: -100
						}]
					}
				]
			};
		}

		function setupMap() {
			map = new google.maps.Map($element[0], getMapOptions());
			latLng = new google.maps.LatLng(config.lat, config.lng);
			map.setCenter(latLng);
		}

		function addInfoWindow() {
      infowindow = new google.maps.InfoWindow({
        content: '<p><b>'+config.name+'</b><br>'+config.info+'</p>'
      });
		}

		function addMarker() {
			var marker = new google.maps.Marker({
				position: latLng,
				map: map,
				title: config.name
			});

			map.setCenter(marker.getPosition());
			infowindow.open(map, marker);
		}

		initialize();

	};
})(jQuery, window.app = window.app || {});
